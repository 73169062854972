import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  typography: {
    h2: {
      fontFamily: ["AvantGardeBold", "Arial", "sans-serif"].join(","),
    },
    h3: {
      fontFamily: ["AvantGardeBold", "Arial", "sans-serif"].join(","),
    },
    h4: {
      fontFamily: ["AvantGardeBold", "Arial", "sans-serif"].join(","),
    },
    h5: {
      fontFamily: ["AvantGardeBold", "Arial", "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ["SF", "Arial", "sans-serif"].join(","),
    },
    p: {
      fontFamily: ["SF", "Arial", "sans-serif"].join(","),
    },
    button: {
      fontFamily: ["SF", "Arial", "sans-serif"].join(","),
      textTransform: "capitalize",
      fontSize: 16,
      width: "fit-content",
    },
    subtitle1: {
      fontFamily: ["Salesforce"].join(","),
    },
    subtitle2: {
      fontFamily: ["Salesforce-Light"].join(","),
      fontWeight: 100,
    },
    body1: {
      fontFamily: ["AvantGardeBold", "Arial", "sans-serif"].join(","),
      fontSize: 14,
    },
    body2: {
      fontFamily: ["ESFBold", "sans-serif"].join(","),
      fontSize: 12,
    },
  },
});

export default Theme;
