import { Box, Container } from "@mui/material";
import "./Clients.css";

const Clients = () => {
  return (
    <Container>
      {" "}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: { base: "70px", md: "70px" },
          flexWrap: "wrap",
          margin: "100px 0",
        }}
      >
        <img
          className="client-logos-techno"
          src={
            process.env.PUBLIC_URL +
            "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Common%20Images/nvidia.webp?ref_type=heads"
          }
          alt="nvidiaLogo"
          loading="lazy"
        ></img>
        <img
          className="client-logos"
          src={
            process.env.PUBLIC_URL +
            "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Common%20Images/tekistic.webp?ref_type=heads"
          }
          alt="tekisticLogo"
          loading="lazy"
        ></img>
        <img
          className="client-logos"
          src={
            process.env.PUBLIC_URL +
            "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Common%20Images/accionLabs.webp?ref_type=heads"
          }
          alt="accionLabsLogo"
          loading="lazy"
        ></img>
        <img
          className="zensark-logo"
          src={
            process.env.PUBLIC_URL +
            "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/zensarkLogo.webp"
          }
          alt="zensarkLogo"
          loading="lazy"
        ></img>
        <img
          className="client-logos-techno"
          src={
            process.env.PUBLIC_URL +
            "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/aequalis-logo.webp"
          }
          alt="accionLabsLogo"
          loading="lazy"
        ></img>

      </Box>
    </Container>
  );
};

export default Clients;
