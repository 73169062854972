import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Typography,
  Menu,
  Divider,
  Button,
  Link,
  TextField,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Navbar.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import MenuList from "../components/header/MenuList";
import MulesoftMenuList from "../components/header/MulesoftMenuList";
import SalesforceMenuList from "../components/header/SalesforceMenuList";
// import DigibeeMenuList from "../components/header/DigibeeMenuList";
import BrandCard from "../components/home/BrandCard";

// <--- Function to elevate the Appbar when scrolled --->
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

// <--- Copyright function --->
function Copyright(props) {
  return (
    <Typography variant="body2" color="#032d60" align="center" {...props}>
      {" © "}
      <Link
        color="#032d60"
        href={process.env.PUBLIC_URL + "/"}
        underline="hover"
        className="copyrightLink"
      >
        MuleCraft India Pvt. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {" | "}
      <Link
        color="#032d60"
        href="#"
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        className="copyrightLink"
      >
        Privacy Policy
      </Link>
    </Typography>
  );
}

// <--- Navbar Component --->
const Header = () => {
  const [isHover, setHoverState] = useState(false);

  // <--- Mobile menu handling function --->
  // const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  // const handleMobileMenu = () => {
  //   setMobileMenuVisible(!isMobileMenuVisible);
  // };

  // <--- Services Menu handling function --->

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorElSalesforce, setAnchorElSalesforce] = useState(null);
  const openSalesforce = Boolean(anchorElSalesforce);

  const [anchorElMulesoft, setAnchorElMulesoft] = useState(null);
  const openMulesoft = Boolean(anchorElMulesoft);

  // const [anchorElDigibee, setAnchorElDigibee] = useState(null);
  // const openDigibee = Boolean(anchorElDigibee);

  const handleMouseOverState = (event) => {
    setAnchorEl(null);
    setAnchorElSalesforce(null);
    setAnchorElMulesoft(null);
    // setAnchorElDigibee(null);
    setHoverState(true);
  };

  const handleMouseLeaveState = () => {
    setHoverState(false);
  };

  const handleMouseOverServiceState = (event) => {
    setHoverState(true);
    setAnchorElSalesforce(null);
    setAnchorElMulesoft(null);
    // setAnchorElDigibee(null);
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOverMenu = (event) => {
    document
      .querySelector(".main-navbar-services-serviceitem")
      .classList.add("hovered");
  };

  const handleClose = (event) => {
    document
      .querySelector(".main-navbar-services-serviceitem")
      .classList.remove("hovered");
    setAnchorEl(null);
    setHoverState(false);
  };

  const handleMouseOverSalesforceState = (event) => {
    setHoverState(true);
    setAnchorEl(null);
    setAnchorElMulesoft(null);
    // setAnchorElDigibee(null);
    setAnchorElSalesforce(event.currentTarget);
  };

  const handleMouseOverSalesforce = (event) => {
    document
      .querySelector(".main-navbar-services-salesforceitem")
      .classList.add("hovered");
  };

  const handleSalesforceClose = (event) => {
    document
      .querySelector(".main-navbar-services-salesforceitem")
      .classList.remove("hovered");
    setAnchorElSalesforce(null);
    setHoverState(false);
  };

  const handleMouseOverMulesoftState = (event) => {
    setHoverState(true);
    setAnchorEl(null);
    setAnchorElSalesforce(null);
    // setAnchorElDigibee(null);
    setAnchorElMulesoft(event.currentTarget);
  };

  const handleMouseOverMulesoft = (event) => {
    document
      .querySelector(".main-navbar-services-mulesoftitem")
      .classList.add("hovered");
  };

  const handleMulesoftClose = (event) => {
    document
      .querySelector(".main-navbar-services-mulesoftitem")
      .classList.remove("hovered");
    setAnchorElMulesoft(null);
    setHoverState(false);
  };

  // const handleMouseOverDigibeeState = (event) => {
  //   setHoverState(true);
  //   setAnchorEl(null);
  //   setAnchorElMulesoft(null);
  //   setAnchorElSalesforce(null);
  //   setAnchorElDigibee(event.currentTarget);
  // };

  // const handleMouseOverDigibee = (event) => {
  //   document
  //     .querySelector(".main-navbar-services-digibeeitem")
  //     .classList.add("hovered");
  // };

  // const handleDigibeeClose = (event) => {
  //   document
  //     .querySelector(".main-navbar-services-digibeeitem")
  //     .classList.remove("hovered");
  //   setAnchorElDigibee(null);
  //   setHoverState(false);
  // };
  // <--- Handling Search component --->
  const [isSearchOpen, setSearchState] = useState(false);

  const handleSearchState = () => {
    setSearchState(!isSearchOpen);
  };
  // <--- Handling Mobile Service menu component --->
  const [isMobileServiceMenuVisible, setMobileServiceMenuVisible] =
    useState(false);

  const handleMobileServiceMenu = () => {
    setMobileServiceMenuVisible(!isMobileServiceMenuVisible);
    setMobileMulesoftMenuVisible(false);
    setMobileSalesforceMenuVisible(false);
    // setMobileDigibeeMenuVisible(false);
  };

  // <--- Handling Mobile Mulesoft menu component --->
  const [isMobileMulesoftMenuVisible, setMobileMulesoftMenuVisible] =
    useState(false);

  const handleMobileMulesoftMenu = () => {
    setMobileMulesoftMenuVisible(!isMobileMulesoftMenuVisible);
    setMobileServiceMenuVisible(false);
    setMobileSalesforceMenuVisible(false);
    // setMobileDigibeeMenuVisible(false);
  };

  // <--- Handling Mobile Salesforce menu component --->
  const [isMobileSalesforceMenuVisible, setMobileSalesforceMenuVisible] =
    useState(false);

  const handleMobileSalesforceMenu = () => {
    setMobileSalesforceMenuVisible(!isMobileSalesforceMenuVisible);
    setMobileMulesoftMenuVisible(false);
    setMobileServiceMenuVisible(false);
    // setMobileDigibeeMenuVisible(false);
  };

  // <--- Handling Mobile Digibee menu component --->
  const [isMobileDigibeeMenuVisible, setMobileDigibeeMenuVisible] =
    useState(false);

  // const handleMobileDigibeeMenu = () => {
  //   setMobileDigibeeMenuVisible(!isMobileDigibeeMenuVisible);
  //   setMobileMulesoftMenuVisible(false);
  //   setMobileServiceMenuVisible(false);
  //   setMobileSalesforceMenuVisible(false);
  // };

  // <--- Handling Menu Drawer component --->
  const [openDrawer, setOpenDrawer] = useState(false);

  const navigate = useNavigate();

  const handleItemClick = (url) => {
    setOpenDrawer(false);
    if (url.startsWith("/")) {
      navigate(url);
    } else {
      window.open(url, "_blank");
    }
  };

  // <--- Styling constants --->
  const mainLinkStyle = {
    fontFamily: "'ESFBold', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    height: 72,
    display: "flex",
    alignItems: "center",
    color: isHover ? "white" : "#032d60",
    position: "relative",
  };

  const partnerLinkStyle = {
    fontFamily: "'ESFBold', sans-serif",
    height: 40,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: "white",
    position: "relative",
    backgroundColor: "rgb(107 16 235)",
    borderRadius: "14px",
    padding: "0px 7px",
  };

  const subLinkStyle = {
    fontFamily: "'ESFBold', sans-serif",
    fontSize: 12,
    color: isHover ? "white" : "#032d60",
    fontWeight: 700,
  };

  const searchTextFieldStyle = {
    width: "200px",
    height: "48px",
    borderRadius: "12px",
    backgroundColor: "aliceblue",
  };

  const drawerItemsStyle = {
    fontFamily: "'ESFBold', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#032d60",
    padding: "30px 10px",
    height: 40,
    display: "flex",
    alignItems: "center",
  };

  return (
    <div>
      <ElevationScroll>
        <AppBar
          className="main-appbar"
          position="fixed"
          sx={{
            bgcolor: isHover ? "#001639" : "white",
            boxShadow: 0.5,
          }}
          elevation={0}
        >
          <BrandCard brandName={"Trusted Digibee Partner"} />
          <Toolbar
            className="main-toolbar"
            sx={{
              zIndex: 16,
              height: 72,
              padding: "0 16px",
              maxWidth: 1160,
              display: "flex",
              flexGrow: 1,
              width: { xs: "auto", sm: "auto", md: "auto", lg: 1160, xl: 1160 },
              justifyContent: "center",
              alignItems: "center",
              margin: { xs: 0, sm: 0, md: 0, lg: "0 auto", xl: "0 auto" },
            }}
          >
            {/* <--- MuleCraft Logo ---> */}
            <IconButton
              size="small"
              edge="start"
              href={process.env.PUBLIC_URL + "/"}
              disableRipple
              sx={{
                width: {
                  xs: "63%",
                  sm: "25%",
                  md: "0%",
                  lg: "20%",
                  xl: "20%",
                },
                display: { xs: "flex", md: "none", lg: "flex" },
                justifyContent: "flex-start",
              }}
            >
              <img
                className="light-version-logo"
                src={
                  process.env.PUBLIC_URL +
                  "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/Mulecraft-simpleLogo.webp?ref_type=heads"
                }
                alt="logo"
                loading="lazy"
              />
            </IconButton>
            <IconButton
              size="small"
              edge="start"
              href={process.env.PUBLIC_URL + "/"}
              disableRipple
              sx={{
                width: {
                  xs: "0%",
                  sm: "0%",
                  md: "12%",
                  lg: "0%",
                  xl: "0%",
                },
                display: { xs: "none", md: "flex", lg: "none" },
                justifyContent: "flex-start",
              }}
            >
              <img
                className="light-version-logo"
                src={
                  process.env.PUBLIC_URL +
                  "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/MC-Icon.webp?ref_type=heads"
                }
                alt="logo"
                loading="lazy"
              />
            </IconButton>
            {/* <--- Navbar Items ---> */}
            <Stack
              direction="row"
              spacing={{ md: 3, lg: 4, xl: 4 }}
              className="navbar-stack"
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "nowrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack
                className="navbar-stack-one"
                direction="row"
                spacing={{ md: 1, lg: 2, xl: 2 }}
                display={{
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                }}
                alignItems={"center"}
              >
                <Link
                  className="main-navbar-services"
                  href={process.env.PUBLIC_URL + "/about"}
                  underline="none"
                  onMouseOver={handleMouseOverState}
                  onMouseLeave={handleMouseLeaveState}
                  rel="noreferrer"
                  style={mainLinkStyle}
                >
                  About
                </Link>
                <div>
                  <Link
                    underline="none"
                    className="main-navbar-services-serviceitem"
                    rel="noreferrer"
                    style={mainLinkStyle}
                    onMouseEnter={handleMouseOverServiceState}
                    aria-controls={open ? "services-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    Services
                  </Link>
                  <Menu
                    className="services-menu"
                    anchorEl={anchorEl}
                    id="services-menu"
                    open={open}
                    sx={{
                      top: 0,
                      padding: 0,
                      position: "absolute",
                      transition: "all .5s ease-in-out",
                      zIndex: 16,
                    }}
                    // onClose={handleClose}
                    // onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "16px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        width: 210,
                        padding: "20px",
                      },
                      onMouseOver: handleMouseOverMenu,
                      onMouseLeave: handleClose,
                    }}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                  >
                    <MenuList />
                  </Menu>
                </div>
                <div>
                  <Link
                    underline="none"
                    className="main-navbar-services-mulesoftitem"
                    rel="noreferrer"
                    style={mainLinkStyle}
                    onMouseEnter={handleMouseOverMulesoftState}
                    aria-controls={openMulesoft ? "mulesoft-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMulesoft ? "true" : undefined}
                  >
                    Mulesoft
                  </Link>
                  <Menu
                    className="mulesoft-menu"
                    anchorEl={anchorElMulesoft}
                    id="mulesoft-menu"
                    open={openMulesoft}
                    sx={{
                      top: 0,
                      padding: 0,
                      position: "absolute",
                      transition: "all .5s ease-in-out",
                      zIndex: 16,
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "16px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        width: 207,
                        padding: "20px",
                      },
                      onMouseOver: handleMouseOverMulesoft,
                      onMouseLeave: handleMulesoftClose,
                    }}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                  >
                    <MulesoftMenuList />
                  </Menu>
                </div>
                <div>
                  <Link
                    underline="none"
                    className="main-navbar-services-salesforceitem"
                    rel="noreferrer"
                    style={mainLinkStyle}
                    onMouseEnter={handleMouseOverSalesforceState}
                    aria-controls={
                      openSalesforce ? "salesforce-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openSalesforce ? "true" : undefined}
                  >
                    Salesforce
                  </Link>
                  <Menu
                    className="salesforce-menu"
                    anchorEl={anchorElSalesforce}
                    id="salesforce-menu"
                    open={openSalesforce}
                    sx={{
                      top: 0,
                      padding: 0,
                      position: "absolute",
                      transition: "all .5s ease-in-out",
                      zIndex: 16,
                    }}
                    // onClose={handleSalesforceClose}
                    // onClick={handleSalesforceClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "16px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        width: 196,
                        padding: "20px",
                      },
                      onMouseOver: handleMouseOverSalesforce,
                      onMouseLeave: handleSalesforceClose,
                    }}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                  >
                    <SalesforceMenuList />
                  </Menu>
                </div>
                {/* <div>
                  <Link
                    href={process.env.PUBLIC_URL + "/digibee"}
                    underline="none"
                    className="main-navbar-services-digibeeitem"
                    rel="noreferrer"
                    style={mainLinkStyle}
                    onMouseEnter={handleMouseOverDigibeeState}
                    aria-controls={openDigibee ? "digibee-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDigibee ? "true" : undefined}
                  >
                    Digibee
                    <Stack direction={'column'} style={partnerLinkStyle} spacing={-0.5}>
                      <Typography fontSize={'14px'}>Digibee</Typography>
                      <Typography fontSize={'8px'}>Partner</Typography>
                    </Stack>
                  </Link>
                  <Menu
                    className="digibee-menu"
                    anchorEl={anchorElDigibee}
                    id="digibee-menu"
                    open={openDigibee}
                    sx={{
                      top: 0,
                      padding: 0,
                      position: "absolute",
                      transition: "all .5s ease-in-out",
                      zIndex: 16,
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "16px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        width: 196,
                        padding: "20px",
                      },
                      onMouseOver: handleMouseOverDigibee,
                      onMouseLeave: handleDigibeeClose,
                    }}
                    transformOrigin={{
                      horizontal: "center",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                  >
                    <DigibeeMenuList />
                  </Menu>
                </div> */}
                <Link
                  href={process.env.PUBLIC_URL + "https://blogs.mulecraft.in/"}
                  underline="none"
                  rel="noreferrer"
                  target="_blank"
                  className="main-navbar-services"
                  style={mainLinkStyle}
                  onMouseOver={handleMouseOverState}
                  onMouseLeave={handleMouseLeaveState}
                >
                  Blog
                </Link>
                <Link
                  href={
                    process.env.PUBLIC_URL +
                    "https://mulecraft.springrecruit.com/careers"
                  }
                  underline="none"
                  className="main-navbar-services"
                  rel="noreferrer"
                  style={mainLinkStyle}
                  onMouseOver={handleMouseOverState}
                  onMouseLeave={handleMouseLeaveState}
                >
                  Careers
                </Link>
              </Stack>
              <Stack
                className="navbar-stack-two"
                direction="row"
                spacing={{ md: 1, lg: 1, xl: 1 }}
                sx={{
                  minWidth: 400,
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
                display={{
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ minWidth: 100, height: 72, justifyContent: "center" }}
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  }}
                >
                  <Link
                    textAlign={"center"}
                    href={process.env.PUBLIC_URL + "/contact"}
                    underline="none"
                    rel="noreferrer"
                    sx={{ textDecoration: "underline", zIndex: 16 }}
                    style={subLinkStyle}
                  >
                    Contact Us
                  </Link>
                  <Typography
                    style={subLinkStyle}
                    align="center"
                    sx={{ zIndex: 16 }}
                  >
                    77084-43455
                  </Typography>
                </Stack>
                {/* <--- Search Component ---> */}
                <IconButton
                  disableRipple
                  sx={{ color: isHover ? "white" : "#032d60" }}
                  onClick={handleSearchState}
                >
                  <SearchIcon />
                </IconButton>
                {isSearchOpen && (
                  <Stack direction="row" spacing={0}>
                    <TextField
                      variant="outlined"
                      placeholder="Search"
                      type="search"
                      className="navbar-search-box"
                      InputProps={{ style: searchTextFieldStyle }}
                    />
                    <IconButton
                      disableRipple
                      onClick={handleSearchState}
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                )}
                <Button
                  className="book-demo-button"
                  sx={{
                    color: "white",
                    minWidth: 120,
                    zIndex: 16,
                    display: isSearchOpen ? "none" : "flex",
                  }}
                  variant="contained"
                  href={process.env.PUBLIC_URL + "/contact"}
                  // onMouseOver={handleMouseOverState}
                  // onMouseLeave={handleMouseLeaveState}
                >
                  Book Demo
                </Button>
              </Stack>
            </Stack>
            <IconButton
              edge="end"
              color="primary"
              size="large"
              disableRipple
              className="menu-icon-button"
              sx={{ display: { md: "none", lg: "none", xl: "none" } }}
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/* </div> */}
      </ElevationScroll>
      <Drawer
        open={openDrawer}
        anchor={"top"}
        onClose={() => setOpenDrawer(false)}
        className="mobile-menu-drawer"
      >
        <ElevationScroll>
          <AppBar
            position="fixed"
            elevation={0}
            sx={{
              backgroundColor: "#032d60",
              padding: "10px 10px",
              boxShadow: 0.5,
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <IconButton
                size="small"
                edge="start"
                href={process.env.PUBLIC_URL + "/"}
                disableRipple
                sx={{
                  width: {
                    xs: "58%",
                    sm: "25%",
                    md: "25%",
                    lg: "25%",
                    xl: "25%",
                  },
                }}
              >
                <img
                  className="light-version-logo"
                  src={
                    process.env.PUBLIC_URL +
                    "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/Mulecraft-simpleLogo.webp?ref_type=heads"
                  }
                  alt="logo"
                />
              </IconButton>
              <IconButton
                size="small"
                edge="start"
                href={process.env.PUBLIC_URL + "/"}
                disableRipple
                sx={{
                  width: {
                    xs: "0%",
                    sm: "0%",
                    md: "12%",
                    lg: "0%",
                    xl: "0%",
                  },
                  display: { xs: "none", md: "flex", lg: "none" },
                  justifyContent: "flex-start",
                }}
              >
                <img
                  className="light-version-logo"
                  src={
                    process.env.PUBLIC_URL +
                    "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/MC-Icon.webp?ref_type=heads"
                  }
                  alt="logo"
                />
              </IconButton>
              {/* <--- Navbar Items ---> */}
              <Stack
                direction="row"
                spacing={{ md: 0, lg: 4, xl: 4 }}
                className="navbar-stack"
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  className="navbar-stack-one"
                  direction="row"
                  spacing={{ md: 1, lg: 2, xl: 2 }}
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  }}
                >
                  <Link
                    className="main-navbar-services"
                    href={process.env.PUBLIC_URL + "/about"}
                    underline="none"
                    onMouseOver={handleMouseOverState}
                    onMouseLeave={handleMouseLeaveState}
                    rel="noreferrer"
                    style={mainLinkStyle}
                  >
                    About
                  </Link>
                  <div>
                    <Link
                      underline="none"
                      className="main-navbar-services-serviceitem"
                      rel="noreferrer"
                      style={mainLinkStyle}
                      onMouseEnter={handleMouseOverServiceState}
                      aria-controls={open ? "services-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      Services
                    </Link>
                    <Menu
                      className="services-menu"
                      anchorEl={anchorEl}
                      id="services-menu"
                      open={open}
                      sx={{
                        top: 0,
                        padding: 0,
                        position: "absolute",
                        transition: "all .5s ease-in-out",
                        zIndex: 16,
                      }}
                      // onClose={handleClose}
                      // onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          borderRadius: "16px",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          width: 210,
                          padding: "20px",
                        },
                        onMouseOver: handleMouseOverMenu,
                        onMouseLeave: handleClose,
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <MenuList />
                    </Menu>
                  </div>
                  <div>
                    <Link
                      underline="none"
                      className="main-navbar-services-mulesoftitem"
                      rel="noreferrer"
                      style={mainLinkStyle}
                      onMouseEnter={handleMouseOverMulesoftState}
                      aria-controls={openMulesoft ? "mulesoft-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMulesoft ? "true" : undefined}
                    >
                      Mulesoft
                    </Link>
                    <Menu
                      className="mulesoft-menu"
                      anchorEl={anchorElMulesoft}
                      id="mulesoft-menu"
                      open={openMulesoft}
                      sx={{
                        top: 0,
                        padding: 0,
                        position: "absolute",
                        transition: "all .5s ease-in-out",
                        zIndex: 16,
                      }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          borderRadius: "16px",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          width: 207,
                          padding: "20px",
                        },
                        onMouseOver: handleMouseOverMulesoft,
                        onMouseLeave: handleMulesoftClose,
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <MulesoftMenuList />
                    </Menu>
                  </div>
                  <div>
                    <Link
                      underline="none"
                      className="main-navbar-services-salesforceitem"
                      rel="noreferrer"
                      style={mainLinkStyle}
                      onMouseEnter={handleMouseOverSalesforceState}
                      aria-controls={
                        openSalesforce ? "salesforce-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openSalesforce ? "true" : undefined}
                    >
                      Salesforce
                    </Link>
                    <Menu
                      className="salesforce-menu"
                      anchorEl={anchorElSalesforce}
                      id="salesforce-menu"
                      open={openSalesforce}
                      sx={{
                        top: 0,
                        padding: 0,
                        position: "absolute",
                        transition: "all .5s ease-in-out",
                        zIndex: 16,
                      }}
                      // onClose={handleSalesforceClose}
                      // onClick={handleSalesforceClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          borderRadius: "16px",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          width: 196,
                          padding: "20px",
                        },
                        onMouseOver: handleMouseOverSalesforce,
                        onMouseLeave: handleSalesforceClose,
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <SalesforceMenuList />
                    </Menu>
                  </div>
                  {/* <div>
                    <Link
                      href={process.env.PUBLIC_URL + "/digibee"}
                      underline="none"
                      className="main-navbar-services-digibeeitem"
                      rel="noreferrer"
                      style={mainLinkStyle}
                      onMouseEnter={handleMouseOverDigibeeState}
                      aria-controls={openDigibee ? "digibee-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openDigibee ? "true" : undefined}
                    >
                      <Stack direction={'column'} style={partnerLinkStyle} spacing={-0.5}>
                      <Typography fontSize={'14px'}>Digibee</Typography>
                      <Typography fontSize={'8px'}>Partner</Typography>
                    </Stack>
                      Digibee
                    </Link>
                    <Menu
                      className="digibee-menu"
                      anchorEl={anchorElDigibee}
                      id="digibee-menu"
                      open={openDigibee}
                      sx={{
                        top: 0,
                        padding: 0,
                        position: "absolute",
                        transition: "all .5s ease-in-out",
                        zIndex: 16,
                      }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          borderRadius: "16px",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          width: 196,
                          padding: "20px",
                        },
                        onMouseOver: handleMouseOverDigibee,
                        onMouseLeave: handleDigibeeClose,
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <DigibeeMenuList />
                    </Menu>
                  </div> */}
                  <Link
                    href={
                      process.env.PUBLIC_URL + "https://blogs.mulecraft.in/"
                    }
                    underline="none"
                    rel="noreferrer"
                    className="main-navbar-services"
                    style={mainLinkStyle}
                    onMouseOver={handleMouseOverState}
                    onMouseLeave={handleMouseLeaveState}
                  >
                    Blog
                  </Link>
                  <Link
                    href={
                      process.env.PUBLIC_URL +
                      "https://mulecraft.springrecruit.com/careers"
                    }
                    underline="none"
                    className="main-navbar-services"
                    rel="noreferrer"
                    style={mainLinkStyle}
                    onMouseOver={handleMouseOverState}
                    onMouseLeave={handleMouseLeaveState}
                  >
                    Careers
                  </Link>
                </Stack>
                <Stack
                  className="navbar-stack-two"
                  direction="row"
                  spacing={{ md: 1, lg: 1, xl: 1 }}
                  sx={{
                    minWidth: 400,
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ minWidth: 100, height: 72, justifyContent: "center" }}
                    display={{
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    }}
                  >
                    <Link
                      textAlign={"center"}
                      href={process.env.PUBLIC_URL + "/contact"}
                      underline="none"
                      rel="noreferrer"
                      sx={{ textDecoration: "underline", zIndex: 16 }}
                      style={subLinkStyle}
                    >
                      Contact Us
                    </Link>
                    <Typography
                      style={subLinkStyle}
                      align="center"
                      sx={{ zIndex: 16 }}
                    >
                      77084-43455
                    </Typography>
                  </Stack>
                  {/* <--- Search Component ---> */}
                  <IconButton
                    disableRipple
                    sx={{ color: isHover ? "white" : "#032d60" }}
                    onClick={handleSearchState}
                  >
                    <SearchIcon />
                  </IconButton>
                  {isSearchOpen && (
                    <Stack direction="row" spacing={0}>
                      <TextField
                        variant="outlined"
                        placeholder="Search"
                        type="search"
                        className="navbar-search-box"
                        InputProps={{ style: searchTextFieldStyle }}
                      />
                      <IconButton
                        disableRipple
                        onClick={handleSearchState}
                        color="primary"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Stack>
                  )}
                  <Button
                    className="book-demo-button"
                    sx={{
                      color: "white",
                      minWidth: 120,
                      zIndex: 16,
                      display: isSearchOpen ? "none" : "flex",
                    }}
                    variant="contained"
                    href={process.env.PUBLIC_URL + "/contact"}
                    // onMouseOver={handleMouseOverState}
                    // onMouseLeave={handleMouseLeaveState}
                  >
                    Book Demo
                  </Button>
                </Stack>
              </Stack>
              <IconButton
                edge="end"
                sx={{ color: "white" }}
                size="large"
                disableRipple
                onClick={() => setOpenDrawer(false)}
                className="menu-icon-button"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <div style={{ paddingTop: "83px" }}>
          <Stack direction="row" spacing={0} sx={{ padding: "10px" }}>
            <IconButton disableRipple color="primary">
              <SearchIcon />
            </IconButton>
            <TextField
              variant="outlined"
              placeholder="Search"
              type="search"
              className="search-textfield"
            />
          </Stack>
          <Divider />
          <ListItem
            button
            onClick={() => handleItemClick("/about")}
            sx={drawerItemsStyle}
          >
            <ListItemText>About</ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={handleMobileServiceMenu}
            sx={drawerItemsStyle}
          >
            <ListItemText>Services</ListItemText>
            <ListItemIcon sx={{ display: "flex", justifyContent: "end" }}>
              {isMobileServiceMenuVisible ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </ListItemIcon>
          </ListItem>
          {isMobileServiceMenuVisible && (
            <div style={{ paddingLeft: "10px" }}>
              <MenuList />
            </div>
          )}
          <Divider />
          <ListItem
            button
            onClick={handleMobileMulesoftMenu}
            sx={drawerItemsStyle}
          >
            <ListItemText>Mulesoft</ListItemText>
            <ListItemIcon sx={{ display: "flex", justifyContent: "end" }}>
              {isMobileMulesoftMenuVisible ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </ListItemIcon>
          </ListItem>
          {isMobileMulesoftMenuVisible && (
            <div style={{ paddingLeft: "10px" }}>
              <MulesoftMenuList />
            </div>
          )}
          <Divider />
          <ListItem
            button
            onClick={handleMobileSalesforceMenu}
            sx={drawerItemsStyle}
          >
            <ListItemText>Salesforce</ListItemText>
            <ListItemIcon sx={{ display: "flex", justifyContent: "end" }}>
              {isMobileSalesforceMenuVisible ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </ListItemIcon>
          </ListItem>
          {isMobileSalesforceMenuVisible && (
            <div style={{ paddingLeft: "10px" }}>
              <SalesforceMenuList />
            </div>
          )}
          <Divider />
          {/* <ListItem
            button
            onClick={handleMobileDigibeeMenu}
            sx={drawerItemsStyle}
          >
            <ListItemText>Digibee</ListItemText>
            <ListItemIcon sx={{ display: "flex", justifyContent: "end" }}>
              {isMobileDigibeeMenuVisible ? (
                <Keybo ardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </ListItemIcon>
          </ListItem>
          {isMobileDigibeeMenuVisible && (
            <div style={{ paddingLeft: "10px" }}>
              <DigibeeMenuList />
            </div>
          )}
          <Divider /> */}
          <ListItem
            button
            onClick={() => handleItemClick("https://blogs.mulecraft.in/")}
            sx={drawerItemsStyle}
          >
            <ListItemText>Blog</ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() =>
              handleItemClick("https://mulecraft.springrecruit.com/careers")
            }
            sx={drawerItemsStyle}
          >
            <ListItemText>Careers</ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => handleItemClick("/contact")}
            sx={drawerItemsStyle}
          >
            <ListItemText>Contact Us</ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => handleItemClick("/contact")}
            sx={drawerItemsStyle}
          >
            <ListItemText>Book Demo</ListItemText>
          </ListItem>
          <Stack
            direction="column"
            spacing={2}
            sx={{ display: "flex", alignItems: "center", padding: "30px" }}
          >
            <Stack direction="row" spacing={2}>
              <Icon icon="jam:facebook-circle" width={35} className="icon" />
              <Icon icon="jam:twitter-circle" width={35} className="icon" />
              <Icon
                href="https://www.linkedin.com/company/mulecraft/"
                icon="typcn:social-linkedin-circular"
                width={35}
                className="icon"
              />
              <Icon icon="jam:youtube-circle" width={35} className="icon" />
            </Stack>
            <Copyright />
          </Stack>
        </div>
      </Drawer>
      {/* <--- Overlay Component ---> */}
      <div
        className="main-appbar-overlay"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          background: "rgba(0,0,0,.7)",
          width: "100%",
          height: "100%",
          zIndex: 16,
          display: isHover ? "block" : "none",
        }}
      ></div>
    </div>
  );
};

export default Header;
