import React, { Suspense, lazy } from "react";
import ReactGA from "react-ga";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import DigibeeIntegration from "../pages/DigibeeIntegration.js";

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");
  ReactGA.initialize("G-YJC7DKG3R6");

  return (
    <Suspense fallback>
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { path: "/", element: <Home /> },
        { path: "/mulesoft-expertise", element: <MulesoftExpertise /> },
        { path: "/mulesoft-development", element: <MulesoftDevelopment /> },
        { path: "/mule-4-migration", element: <Mule4Migration /> },
        { path: "/mule-b2b-integration", element: <MuleB2BIntegration /> },
        {
          path: "/operations-and-maintenance",
          element: <OperationsMaintenance />,
        },
        { path: "/ui-design", element: <UIdesign /> },
        {
          path: "/integrated-web-design",
          element: <IntegratedWebDevelopment />,
        },
        { path: "/snaplogic-strategies", element: <Snaplogic /> },
        { path: "/apigee-api-solutions", element: <Apigee /> },
        { path: "/sales-cloud", element: <SalesforceSalesCloud /> },
        {
          path: "/salesforce-dataorg-migration",
          element: <SalesforceDataOrgMigration />,
        },
        {
          path: "/salesforce-managed-services",
          element: <SalesforceManagedServices />,
        },
        {
          path: "/digibee-integration-services",
          element: <DigibeeIntegrationServices />,
        },
        { path: "/about", element: <About /> },
        { path: "/training&certification", element: <Training /> },
        { path: "/contact", element: <ContactUs /> },
        { path: "/team", element: <Team /> },
        { path: "*", element: <PageNotfound /> },
        { path: "/coming-soon", element: <AComingSoon /> },
        { path: "/mulesoft-learning-path", element: <MuleLearning /> },
        { path: "/snaplogic-learning-path", element: <SnapLearning /> },
        { path: "/mulesoft-services", element: <MulesoftServices /> },
        { path: "/privacy-policy", element: <Privacy /> },
        { path: "/terms-of-use", element: <Terms /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    {
      path: "*",
      // element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <PageNotfound /> },
        // { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

//HOME
const Home = Loadable(lazy(() => import("../pages/MuleCraftHome")));
const About = Loadable(lazy(() => import("../pages/About")));
const MulesoftServices = Loadable(
  lazy(() => import("../pages/MulesoftServices"))
);
const MulesoftExpertise = Loadable(
  lazy(() => import("../pages/MulesoftExpertise"))
);
const MulesoftDevelopment = Loadable(
  lazy(() => import("../pages/MulesoftDevelopment"))
);
const Mule4Migration = Loadable(lazy(() => import("../pages/Mule4Migration")));
const MuleB2BIntegration = Loadable(
  lazy(() => import("../pages/MuleB2BIntegration"))
);
const OperationsMaintenance = Loadable(
  lazy(() => import("../pages/OperationsMaintenance"))
);
const UIdesign = Loadable(lazy(() => import("../pages/UIdesign")));
const IntegratedWebDevelopment = Loadable(
  lazy(() => import("../pages/IntegratedWebDevelopment"))
);
const Snaplogic = Loadable(lazy(() => import("../pages/Snaplogic")));
const Apigee = Loadable(lazy(() => import("../pages/Apigee")));
const SalesforceSalesCloud = Loadable(
  lazy(() => import("../pages/SalesforceSalesCloud"))
);
const SalesforceDataOrgMigration = Loadable(
  lazy(() => import("../pages/SalesforceDataOrgMigration"))
);
const SalesforceManagedServices = Loadable(
  lazy(() => import("../pages/SalesforceManagedServices"))
);
const DigibeeIntegrationServices = Loadable(
  lazy(() => import("../pages/DigibeeIntegration"))
);
const Team = Loadable(lazy(() => import("../pages/Team")));
const Training = Loadable(lazy(() => import("../pages/Training&certificate")));
const ContactUs = Loadable(lazy(() => import("../pages/ContactUs")));
const PageNotfound = Loadable(lazy(() => import("../pages/PageNotFound")));
const AComingSoon = Loadable(lazy(() => import("../common/ComingSoon")));
const MuleLearning = Loadable(lazy(() => import("../pages/Mule-learning.js")));
const SnapLearning = Loadable(lazy(() => import("../pages/Snap-learning")));
const Privacy = Loadable(lazy(() => import("../components/privacy/privacy")));
const Terms = Loadable(lazy(() => import("../components/terms/terms")));
