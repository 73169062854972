import { Button, Stack, Typography } from "@mui/material";

const DigibeeTeam = () => {
  const productTeamTitleStyle = {
    color: "#032d60",
    lineHeight: { xs: "30px", sm: "32px", md: "32px", lg: "32px", xl: "32px" },
    fontWeight: 600,
    fontSize: { xs: "1.5rem", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
  };

  const productTeamContentStyle = {
    fontSize: { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 },
    color: "#181818",
    lineHeight: { xs: "150%", sm: "150%", md: "150%", lg: "150%", xl: "150%" },
  };

  return (
    <div
      className="service-banner-three"
      style={{
        background:
          "linear-gradient(180deg, rgba(233,247,255,1) 0%, rgba(255,255,255,1) 100%)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          maxWidth: 1160,
        }}
      >
        <Stack
          direction="column"
          spacing={4}
          sx={{
            maxWidth: { xs: 280, sm: 600, md: 500, lg: 600, xl: 600 },
          }}
        >
          <img
            src={
              "../digibeePartnerThumb2.webp"
            }
            alt="productTeamBannerThumb"
            loading="lazy"
          ></img>
        </Stack>
        <Stack direction="column" spacing={3} sx={{ maxWidth: 650 }}>
          <Typography variant="h3" sx={productTeamTitleStyle}>
            Architects of Versatile Connectivity
          </Typography>
          <Typography variant="p" sx={productTeamContentStyle}>
            Our integration experts sculpt seamless collaboration, transforming complexity into cohesive solutions.
          </Typography>
          <Typography variant="p" sx={productTeamContentStyle}>
          Crafting pathways of dynamic connectivity, our integration experts, armed with Digibee expertise, redefine versatility in every solution. From complexity to cohesion, they architect a network that adapts and thrives.
          </Typography>
          <Button
            variant="contained"
            size="large"
            href={process.env.PUBLIC_URL + "/team"}
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: 16,
              width: "fit-content",
              ":hover":{
                backgroundColor: "#032d60",
              }
            }}
            className="banner-explore-button"
          >
            Our Team
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default DigibeeTeam;
