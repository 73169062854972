import Router from "./routes";
import "./index.css";

import ScrollToTop from "./components/ScrollToTop";

export default function App() {
  return (
    <div>
      <ScrollToTop />
      <Router />
    </div>
  );
}
