import { Box, Stack, Typography } from "@mui/material";

export default function BrandCard({brandName}){
    return(
            <Stack direction={'row'} height={'35px'} bgcolor={'#55cbcb'}
                   display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'0px 20px'}>
                <img src="https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/digibeeIcon.webp" alt="digibee-icon"
                    style={{height:'25px'}}/>
                <Typography fontSize={'12px'} color={'#032d60'}>{brandName}</Typography>
            </Stack>
    )
}