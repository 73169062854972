import Header from "../common/Header";
import { Button, Stack, Typography } from "@mui/material";
import Footer from "../common/Footer";
import Theme from "../Theme";
import { ThemeProvider } from "@mui/material/styles";
import "./MulesoftExpertise.css";
import SEO from "../common/SEO";
import Clients from "../components/home/Clients";
import BlogCards from "../components/services/BlogCards";
import WhyUs from "../components/services/WhyUs";
import DigibeeTeam from "../components/services/DigibeeTeam";
import DigibeePartnership from "../components/services/DigibeePartnership";

const DigibeeIntegration = () => {
  const webDevSectionTitleStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1160,
    width: 1160,
  };

  const webDevTitleStyle = {
    color: "#032d60",
    lineHeight: { xs: "30px", sm: "32px", md: "32px", lg: "32px", xl: "32px" },
    fontWeight: 600,
    fontSize: { xs: "1.5rem", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
  };

  const webDevContentStyle = {
    fontSize: { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 },
    color: "#181818",
    lineHeight: { xs: "150%", sm: "150%", md: "150%", lg: "150%", xl: "150%" },
  };

  return (
    <ThemeProvider theme={Theme}>
      <SEO title={"Digibee Integration"} />
      <div>
        <Header />
        <DigibeePartnership/>
        <div
          className="service-banner-one"
          style={{
            background:
              "linear-gradient(180deg, rgba(233,247,255,1) 0%, rgba(255,255,255,1) 100%)",
            display: "flex",
            justifyContent: "center",
            padding:'30px'
          }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            spacing={3}
            sx={webDevSectionTitleStyle}
          >
            <Stack
              direction="column"
              spacing={4}
              sx={{
                maxWidth: { xs: 280, sm: 600, md: 500, lg: 600, xl: 600 },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#032d60",
                  fontWeight: 400,
                  fontSize: {
                    xs: "2rem",
                    sm: "48px",
                    md: "48px",
                    lg: "48px",
                    xl: "48px",
                  },
                  lineHeight: {
                    xs: "40px",
                    sm: "56px",
                    md: "56px",
                    lg: "56px",
                    xl: "56px",
                  },
                }}
              >
                Digibee: Crafting Superior Solutions Beyond Compare
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: 15, sm: 20, md: 20, lg: 20, xl: 20 },
                  fontWeight: 400,
                  color: "#181818",
                  lineHeight: {
                    xs: "20px",
                    sm: "30px",
                    md: "30px",
                    lg: "30px",
                    xl: "30px",
                  },
                }}
              >
                Offering a transformative advantage to businesses by streamlining their operations across diverse systems through Digibee's unparalleled agility and scalability, ensuring adaptability to evolving integration needs. We enhance your business collaboration and effectiveness through our integration services, leveraging Digibee's resilient features for seamless operations and optimal performance.
              </Typography>
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                }}
                spacing={3}
                display="flex"
                alignItems="center"
              >
                <Button
                  className="banner-explore-button"
                  variant="contained"
                  size="large"
                  href={process.env.PUBLIC_URL + "/contact"}
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: 16,
                    width: "fit-content",
                    ":hover":{
                      backgroundColor: "#032d60"
                    }
                  }}
                >
                  Contact Us
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={4}
              sx={{ maxWidth: 600, display: "flex", alignItems: "center" }}
            >
              <img
                className="service-banner-thumb-one"
                src={
                  "../digibeePartnerThumb4.webp"
                }
                alt="digibeePartnerThumb4"
                loading="lazy"
              ></img>
            </Stack>
          </Stack>
        </div>
        <div
          className="service-banner-three"
          style={{
            background:
              "linear-gradient(180deg, rgba(233,247,255,1) 0%, rgba(255,255,255,1) 100%)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              maxWidth: 1160,
            }}
          >
            <Stack direction="column" sx={{ maxWidth: { sm: 600, md: 200 } }}>
              <Typography variant="h3" sx={webDevTitleStyle}>
                Transforming Complexity Into Clarity
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={4}
              sx={{
                maxWidth: { xs: 300, sm: 500, md: 600, lg: 600, xl: 600 },
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className="service-banner-thumb-two"
                src={
                  "../digibeePartnerThumb3.webp"
                }
                alt="digibeePartnerThumb3"
                loading="lazy"
              ></img>
            </Stack>
            <Stack
              direction="column"
              spacing={3}
              sx={{ maxWidth: { sm: 650, md: 350 } }}
            >
              <Typography variant="p" sx={webDevContentStyle}>
              Elevate your data landscape with our integration services, leveraging Digibee's advanced features for seamless connectivity and transformative insights. Experience efficiency and innovation in every integration journey.
              </Typography>
              <Button
                className="banner-outlined-button"
                variant="outlined"
                size="large"
                href={process.env.PUBLIC_URL + "/contact"}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 16,
                  color: "#032d60",
                  borderColor: "#032d60",
                  width: "fit-content",
                  ":hover":{
                    backgroundColor: "#032d60",
                    color: "white",
                    borderColor: "#032d60"
                  }
                }}
              >
                Book Demo
              </Button>
            </Stack>
          </Stack>
        </div>
        <DigibeeTeam />
        <WhyUs />
        <BlogCards />
        <Clients />
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default DigibeeIntegration;
