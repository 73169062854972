import React from "react";
import {
  Stack,
  Link,
} from "@mui/material";

const SalesforceMenuList = () => {

    return (
            <Stack direction="column" spacing={0}>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={ process.env.PUBLIC_URL + "/sales-cloud" }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Sales Cloud
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={ process.env.PUBLIC_URL + "/salesforce-dataorg-migration" }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Data & Org Migration
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={ process.env.PUBLIC_URL + "/salesforce-managed-services" }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Managed Services
                </Link>
            </Stack>
    )
}

export default SalesforceMenuList;