import { Button, Stack, Typography } from "@mui/material";

const DigibeePartnership = () => {

    const partnerSectionTitleStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: 1160,
        // width: 1160,
      };

  const partnerSubTitleStyle = {
    color: "#032d60",
    lineHeight: { xs: "30px", sm: "32px", md: "32px", lg: "32px", xl: "32px" },
    fontWeight: 600,
    fontSize: { xs: "1.5rem", sm: "24px", md: "24px", lg: "24px", xl: "24px" },
  };

  const productTeamContentStyle = {
    fontSize: { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 },
    color: "#181818",
    lineHeight: { xs: "150%", sm: "150%", md: "150%", lg: "150%", xl: "150%" },
  };

  return (
    <div
      className="service-banner-one"
      style={{
        background:"url(../digibeePartnerThumbAddition.jpg)",
        backgroundSize:"cover",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          maxWidth: 1160,
        }}
      >
        <Stack
          direction="column"
          spacing={0}
          sx={{
            maxWidth: { xs: 280, sm: 600, md: 500, lg: 600, xl: 600 },
          }}
        >
          <img
            src={
              "../digibeePartnerThumb.webp"
            }
            alt="digibeePartnerThumb1"
            loading="lazy"
          ></img>
        </Stack>
        <Stack direction="column" spacing={3} sx={{ maxWidth: 650,padding:'30px 0px 0px 0px' }}>
        <Typography
                variant="h3"
                sx={{
                  color: "#032d60",
                  fontWeight: 400,
                  fontSize: {
                    xs: "2rem",
                    sm: "48px",
                    md: "48px",
                    lg: "48px",
                    xl: "48px",
                  },
                  lineHeight: {
                    xs: "40px",
                    sm: "56px",
                    md: "56px",
                    lg: "56px",
                    xl: "56px",
                  },
                }}
              >
                We've Joined Forces With Digibee As System Integrator Partner
              </Typography>
          <Typography variant="p"
                sx={{
                  fontSize: { xs: 15, sm: 20, md: 20, lg: 20, xl: 20 },
                  fontWeight: 400,
                  color: "#181818",
                  lineHeight: {
                    xs: "20px",
                    sm: "30px",
                    md: "30px",
                    lg: "30px",
                    xl: "30px",
                  },
                }}>
          We are determined to deliver a streamlined and efficient integration process, enabling businesses to connect applications effortlessly through Digibee's advanced API management capabilities.
          </Typography>
          <Typography variant="p"
                sx={{
                  fontSize: { xs: 15, sm: 20, md: 20, lg: 20, xl: 20 },
                  fontWeight: 400,
                  color: "#181818",
                  lineHeight: {
                    xs: "20px",
                    sm: "30px",
                    md: "30px",
                    lg: "30px",
                    xl: "30px",
                  },
                }}>
          This strategic partnership with Digibee elevates our integration services, delivering a heightened level of efficiency, innovation and cohesive connectivity for our clients.
          </Typography>
          <Button
            variant="contained"
            size="large"
            href={process.env.PUBLIC_URL + "https://www.digibee.com/"}
            target="_blank"
            rel="noreferrer"
            sx={{
              color: "white",
              textTransform: "capitalize",
              fontSize: 16,
              width: "fit-content",
              ":hover":{
                backgroundColor: "#032d60",
              }
            }}
            className="banner-explore-button"
          >
            Visit Digibee
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default DigibeePartnership;
