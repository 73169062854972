import React from "react";
import {
  Stack,
  Chip,
  Divider,
  Link,
} from "@mui/material";

const MenuList = () => {

    const newChipStyle = {
        color: "#b60554",
        backgroundColor: "#fef0f3",
        fontSize: 14,
        height: "auto",
        width: "fit-content",
        fontFamily: "system-ui",
      };

    return (
            <Stack direction="column" spacing={0}>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL + "/snaplogic-strategies"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Snaplogic Strategies
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL + "/apigee-api-solutions"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Apigee API Solutions
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL + "/training&certification"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Training & Certification
                </Link>
                <Divider>
                    <Chip
                        label="Frontend"
                        size="small"
                        style={newChipStyle}
                    />
                </Divider>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={process.env.PUBLIC_URL + "/ui-design"}
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    User Interface Design
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL +
                        "/integrated-web-design"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Integrated Web Design
                </Link>
            </Stack>
    )
}

export default MenuList;