import React from "react";
import {
  Stack,
  Link,
} from "@mui/material";

const MulesoftMenuList = () => {

    return (
            <Stack direction="column" spacing={0}>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={process.env.PUBLIC_URL + "/mulesoft-expertise"}
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Mulesoft Expertise
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL + "/mulesoft-development"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Mulesoft Development
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={process.env.PUBLIC_URL + "/mule-4-migration"}
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Mule 4 Migration
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL + "/mule-b2b-integration"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Mule B2B Integration
                </Link>
                <Link
                    className="navbar-menu-items"
                    underline="hover"
                    href={
                        process.env.PUBLIC_URL +
                        "/operations-and-maintenance"
                    }
                    sx={{
                        fontFamily: "'ESFBold', sans-serif",
                        lineHeight: "20px",
                        fontSize: 14,
                        padding: "8px 6px",
                        borderRadius: 1,
                        width: "fit-content",
                    }}
                >
                    Ops & Maintenance
                </Link>
            </Stack>
    )
}

export default MulesoftMenuList;