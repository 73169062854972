import { Box, Stack, Typography, styled, Link, Container } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

const MenuBox = styled(Box)({
  display: "flex",
  gap: 30,
});
const SocialBox = styled(Box)({
  display: "flex",
  gap: 20,
  justifyContent: "space-between",
});

const linkStyle = { fontSize: 13 };

const Footer = () => {
  return (
    <Container style={{ bottom: "30px" }}>
      <MenuBox
        sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}
        height={400}
        width="100%"
        justifyContent={"space-between"}
      >
        <Box
          sx={{
            background: "white",
            width: "100%",
            alignItems: "center",
          }}
          flexDirection={{ xs: "row", md: "row" }}
        >
          <Stack
            direction={{ xs: "row", md: "row" }}
            paddingTop={7}
            sx={{ display: "flex" }}
            spacing={{ md: 6, lg: 10 }}
          >
            <Box flex={2}>
              <Typography
                color={"black"}
                align={"left"}
                variant="body1"
                component="body1"
              >
                <b>Products</b>
              </Typography>

              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href="https://community.platform.mulecraft.in/"
                  target="blank"
                  rel="noreferrer"
                  underline="hover"
                  className="icon"
                  variant="body2"
                  color="#0176d3"
                  style={linkStyle}
                >
                  {"Community Anypoint Platform"}
                </Link>{" "}
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={"https://training.mulecraft.in/"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                  color="#0176d3"
                >
                  {"MuleCraft Academy"}
                </Link>{" "}
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/coming-soon"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                  color="#0176d3"
                >
                  {"Active Mq"}
                </Link>{" "}
              </Typography>
              <Box flex={2} sx={{ marginTop: "30px" }}>
                <Typography
                  color={"black"}
                  align={"left"}
                  variant="body1"
                  component="body1"
                >
                  <b> Resources</b>
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: "#0176d3",
                    paddingTop: 3,
                  }}
                >
                  <Link
                    href={
                      process.env.PUBLIC_URL + "https://blogs.mulecraft.in/"
                    }
                    underline="hover"
                    className="icon"
                    variant="body2"
                    style={linkStyle}
                  >
                    {" "}
                    Blog
                  </Link>
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: "#0176d3",
                    paddingTop: 3,
                  }}
                >
                  <Link
                    href={
                      process.env.PUBLIC_URL +
                      "https://mulecraft.springrecruit.com/careers"
                    }
                    underline="hover"
                    className="icon"
                    variant="body2"
                    style={linkStyle}
                  >
                    {" "}
                    Careers
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box flex={2}>
              <Typography
                color={"black"}
                align={"left"}
                variant="body1"
                component="body1"
              >
                <b> Mulesoft Services</b>
              </Typography>

              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/mulesoft-expertise"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Mulesoft Expertise
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/mulesoft-development"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Mulesoft Development
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/mule-4-migration"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Mule-4 Migration
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/mule-b2b-integration"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Mule B2B Integration
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/operations-and-maintenance"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Ops & Maintenance
                </Link>
              </Typography>
            </Box>
            <Box flex={2}>
              <Typography
                color={"black"}
                align={"left"}
                variant="body1"
                component="body1"
              >
                <b> Salesforce Services</b>
              </Typography>

              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/sales-cloud"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Sales Cloud
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={
                    process.env.PUBLIC_URL + "/salesforce-dataorg-migration"
                  }
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Data & Org Migration
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/salesforce-managed-services"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Managed Services
                </Link>
              </Typography>
            </Box>
            <Box flex={2} sx={{ marginTop: "30px" }}>
              <Typography
                color={"black"}
                align={"left"}
                variant="body1"
                component="body1"
              >
                <b> Other Services</b>
              </Typography>

              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/ui-design"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  {" "}
                  User Interface Design
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/integrated-web-design"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  {" "}
                  Integrated Web Design
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/snaplogic-strategies"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  {" "}
                  Snaplogic Strategies
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/apigee-api-solutions"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  {" "}
                  Apigee API Solutions
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/training&certification"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  {" "}
                  Training & Certification
                </Link>
              </Typography>
            </Box>
            <Box flex={2}>
              <Typography
                color={"black"}
                align={"left"}
                variant="body1"
                component="body1"
              >
                <b>Support</b>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/contact"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Contact Us
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/privacy-policy"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "#0176d3",
                  paddingTop: 3,
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/terms-of-use"}
                  underline="hover"
                  className="icon"
                  variant="body2"
                  style={linkStyle}
                >
                  Terms of Use
                </Link>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </MenuBox>
      <Stack
        direction={{ xs: "column", md: "row" }}
        paddingTop={8}
        marginBottom={3}
        spacing={1}
      >
        <Typography
          flex={1}
          color={"black"}
          maxWidth={400}
          sx={{ display: "flex", alignItems: "start" }}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/Common%20Images/Mulecraft-simpleLogo.webp?ref_type=heads"
            }
            loading="lazy"
            alt="logo"
            className="img"
            height="auto"
            width="50%"
          />
        </Typography>
        <Typography flex={1} color={"black"} flexBasis={1}>
          <Typography color={"black"} component="body2" variant="body2">
            © Copyright 2023 Mulecraft{" "}
            <Link
              href={process.env.PUBLIC_URL + "/coming-soon"}
              underline="hover"
              className="icon"
              fontSize={11}
              color="#0176d3"
            >
              {"All rights reserved."}
            </Link>{" "}
            <Typography display={"flex"} sx={{ marginTop: 1 }}>
              <Typography
                component="body2"
                variant="body2"
                sx={{
                  cursor: "pointer",
                  fontSize: "11px",
                  color: "#0176d3",
                  margin: "2px",
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/privacy-policy"}
                  underline="hover"
                  className="icon"
                >
                  Privacy{" "}
                  <span style={{ marginRight: 10, marginLeft: 10 }}>|</span>
                </Link>
              </Typography>
              <Typography
                component="body2"
                variant="body2"
                sx={{
                  cursor: "pointer",
                  fontSize: "11px",
                  color: "#0176d3",
                  margin: "2px",
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/terms-of-use"}
                  underline="hover"
                  className="icon"
                >
                  Terms{" "}
                  <span style={{ marginRight: 10, marginLeft: 10 }}>|</span>
                </Link>
              </Typography>
              <Typography
                component="body2"
                variant="body2"
                sx={{
                  cursor: "pointer",
                  fontSize: "11px",
                  color: "#0176d3",
                  margin: "2px",
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/coming-soon"}
                  underline="hover"
                  className="icon"
                >
                  Cookie{" "}
                  <span style={{ marginRight: 10, marginLeft: 10 }}>|</span>
                </Link>
              </Typography>
              <Typography
                component="body2"
                variant="body2"
                sx={{
                  cursor: "pointer",
                  fontSize: "11px",
                  color: "#0176d3",
                  margin: "2px",
                }}
              >
                <Link
                  href={process.env.PUBLIC_URL + "/coming-soon"}
                  underline="hover"
                  className="icon"
                >
                  Settings{" "}
                </Link>
              </Typography>
            </Typography>
            <Typography display={"flex"}>
              <Typography
                component="body2"
                variant="body2"
                sx={{
                  cursor: "pointer",
                  fontSize: "12px",
                  color: "#0176d3",
                  paddingTop: 1,
                  textAlign: "center",
                }}
              >
                <Link
                  sx={{ display: "flex", alignItems: "center" }}
                  underline="hover"
                  className="icon"
                  href={process.env.PUBLIC_URL + "/coming-soon"}
                >
                  <span style={{ marginRight: "5px" }}>
                    Your Privacy Choices{" "}
                  </span>

                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/feature/images/About/target.png"
                    }
                    loading="lazy"
                    alt="logo"
                    height="10px"
                    width="20px"
                  />
                </Link>
              </Typography>
            </Typography>
          </Typography>
        </Typography>
        <Typography flex={1} color={"black"} sx={{ display: "flex" }}>
          <SocialBox>
            <Link
              target="block"
              href="https://www.facebook.com/profile.php?id=100095449842036"
            >
              <Icon icon="jam:facebook-circle" width={35} className="icon" />
            </Link>
            <Link target="block" href="https://twitter.com/mulecraft_in">
              <Icon icon="jam:twitter-circle" width={35} className="icon" />
            </Link>
            <Link
              target="block"
              href="https://www.linkedin.com/company/mulecraft/"
            >
              <Icon
                icon="typcn:social-linkedin-circular"
                width={35}
                className="icon"
              />
            </Link>
            <Link target="block" href="https://www.youtube.com/@MuleCraft-mc">
              <Icon icon="jam:youtube-circle" width={35} className="icon" />
            </Link>
          </SocialBox>
        </Typography>
      </Stack>
    </Container>
  );
};

export default Footer;
