import BlogData from "../home/BlogData.json";
import { Button, Stack, Typography, Paper, Box } from "@mui/material";
import "../home/blogSlides.css";

const BlogCards = () => {
  const blogDetails = BlogData;
  const cardColours = ["#fcc003", "#0176d3", "#5867e8"];

  const blogTitleStyle = {
    color: "#032d60",
    lineHeight: { xs: "28px", sm: "32px", md: "32px", lg: "32px", xl: "32px" },
    fontWeight: 600,
    fontSize: { xs: "16px", sm: "22px", md: "22px", lg: "22px", xl: "22px" },
  };

  const blogContentStyle = {
    fontSize: { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 },
    fontWeight: 400,
    color: "#181818",
    lineHeight: { xs: "25px", sm: "30px", md: "30px", lg: "30px", xl: "30px" },
  };

  const blogBannerTitleStyle = {
    color: "#032d60",
    maxWidth: 1160,
    fontWeight: 400,
    lineHeight: "48px",
    fontSize: { xs: "2rem", sm: "40px", md: "40px", lg: "40px", xl: "40px" },
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fefefe",
          textAlign: "center",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
        }}
        className="blog-cards-banner"
      >
        <Typography variant="h2" sx={blogBannerTitleStyle}>
          Explore Our Blog for Expert Perspectives and Inspiration
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          }}
          display="flex"
          alignItems="center"
          sx={{ width: { xs: 300, sm: "auto" } }}
        >
          {blogDetails.map((data, index) => (
            <Paper
              elevation={2}
              key={index}
              className="blog-cards"
              sx={{
                width: { xs: 290, sm: 360 },
                height: 363,
                borderRadius: "12px",
                margin: "20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: 8,
                  backgroundColor: cardColours[index],
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }}
              ></Box>
              <Stack
                direction="column"
                spacing={2}
                padding="30px"
                display="flex"
                justifyContent="space-between"
                sx={{ maxHeight: "370px" }}
              >
                <Stack direction="column" spacing={1} sx={{ height: 250 }}>
                  <Typography
                    variant="p"
                    sx={{ color: "#032d60", fontSize: "14px" }}
                  >
                    {data.subtitle}
                  </Typography>
                  <Typography variant="h3" sx={blogTitleStyle}>
                    {data.title}
                  </Typography>
                  <Typography variant="p" sx={blogContentStyle}>
                    {data.content}
                  </Typography>
                </Stack>
                <Stack direction="column">
                  <Button
                    className="banner-outlined-button"
                    variant="outlined"
                    size="small"
                    href={"https://blogs.mulecraft.in/"+data.path}
                    sx={{
                      textTransform: "capitalize",
                      fontSize: 16,
                      color: "#032d60",
                      borderColor: "#032d60",
                      width: "fit-content",
                    }}
                  >
                    Read More
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default BlogCards;
