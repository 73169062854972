import { Stack, Typography } from "@mui/material";

const WhyUs = () => {

    const serviceBannerTitleStyle = {
        color: "#032d60", maxWidth:1160, fontWeight: 400,lineHeight:'48px',
        fontSize: { xs: "2rem", sm: "40px", md: "40px", lg: "40px", xl: "40px" } 
    }

    const whyUsStackStyle = { 
        display:'flex',alignItems:'center',
        width: { sm: 280, md: 320, lg: 350, xl: 350 }, }

    const benefitsTitleStyle = {
        color: "#032d60", lineHeight: { xs: "30px", sm: "32px", md: "32px", lg: "32px", xl: "32px"},
        fontWeight: 600, fontSize: { xs: "1.5rem", sm: "24px", md: "24px", lg: "24px", xl: "24px"}
    }
    
    const benefitsContentStyle = {
        fontSize: { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 },width:'max-content',
        maxWidth: { xs: 280, sm: 700, md: 300, lg: 350, xl: 350 },
        color: "#181818", lineHeight: { xs: "150%", sm: "150%", md: "150%", lg: "150%", xl: "150%" }
    }

    return(
        <>
        <div
            style={{
              backgroundColor: "#fefefe",
              textAlign: "center",
              margin: "0 auto",display:'flex',justifyContent:'center'
            }}
            className="service-banner-two"
          >
            <Typography
              variant="h2"
              sx={serviceBannerTitleStyle}
            >
              Why choose MuleCraft ?
            </Typography>
          </div>
          <div className="why-us-section" style={{
              background:
                "linear-gradient(180deg, rgba(233,247,255,1) 0%, rgba(255,255,255,1) 100%)",
                display:'flex',justifyContent:'center'
            }}>
            <Stack direction='column' spacing={{xs:6,sm:8}}>
                <Stack direction={{
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                                xl: "row",
                            }} 
                        spacing={{xs:6,sm:4,md:1,lg:8}}
                        sx={{   display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                maxWidth:1160
                            }}>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/bestIcon.png?ref_type=heads"}
                            alt="bestIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Best-in-industry
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        We are committed to providing our clients with the best-in-class services, exceeding expectations and delivering exceptional results every step of the way.
                        </Typography>
                    </Stack>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={"https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/expertiseIcon.png?ref_type=heads"}
                            alt="expertiseIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Elite Skills
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        Our team of highly skilled specialists is dedicated to handling projects with expertise, ensuring top-notch execution and delivering superior outcomes for our clients.
                        </Typography>
                    </Stack>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/synergyIcon.png?ref_type=heads"}
                            alt="synergyIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Synergy
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        By fostering a strong communication and synergy with our clients, we drive collaborative innovation, unlock shared insights, and achieve transformative results together.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={{
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                                xl: "row",
                            }} 
                        spacing={{xs:6,sm:4,md:2,lg:8}}
                        sx={{   display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                maxWidth:1160
                            }}>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/timeIcon.png?ref_type=heads"}
                            alt="timeIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Delay? Never!
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        Partnering with us, clients experience the benefits of timely project delivery, enabling them to stay on track, seize opportunities, and achieve their business goals efficiently.
                        </Typography>
                    </Stack>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/securityIcon.png?ref_type=heads"}
                            alt="securityIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Invulnerable
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        MuleCraft specializes in developing highly secure projects, providing clients with peace of mind and safeguarding their valuable data in an ever-evolving threat landscape.
                        </Typography>
                    </Stack>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={"https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/flexibilityIcon.png?ref_type=heads"}
                            alt="flexibilityIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Highly Flexible
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        We meticulously code with flexibility in mind, allowing for seamless adaptability and enabling the efficient reuse of components, saving time and resources for our clients.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={{
                                xs: "column",
                                sm: "column",
                                md: "row",
                                lg: "row",
                                xl: "row",
                            }} 
                            spacing={{xs:6,sm:4,md:2,lg:8}}
                            sx={{   display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    maxWidth:1160
                                }}>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/walletIcon.png?ref_type=heads"}
                            alt="walletIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Efficiently priced
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        We excel in combining cost-effective solutions with superior quality services to meet all your technological to ensure maximum value and satisfaction while optimizing resources.
                        </Typography>
                    </Stack>
                    <Stack direction='column' spacing={4} sx={whyUsStackStyle}>
                        <img className="service-icons"
                            src={ "https://gitlab.mulecraft.in/mc-public-group/mc-website-image-data/-/raw/develop/Services/supportIcon.png?ref_type=heads"}
                            alt="supportIcon"
                            loading="lazy"
                        ></img>
                        <Typography
                            variant="h3"
                            sx={benefitsTitleStyle}
                            >
                        Stellar assistance
                        </Typography>
                        <Typography
                            variant="p"
                            sx={benefitsContentStyle}
                            >
                        We deliver exceptional customer support, ensuring clients to receive expert assistance as it builds trust, enhances satisfaction, and fosters long-term relationships.
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>    
          </div>
        </>
    )
}

export default WhyUs;